import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import formatISO from 'date-fns/formatISO';
import format from 'date-fns/format';
import { DATE_FORMAT } from 'app/constants/dateConstants';

export const rewardTypes = [
  { value: 0, label: 'Everyone' },
  { value: 10, label: 'All' },
  { value: 20, label: 'Bronze' },
  { value: 30, label: 'Silver' },
  { value: 40, label: 'Gold' },
  { value: 50, label: 'Diamond' },
];

export const rewardsSettingsDefaultValues = {
  reward_name: '',
  reward_probability: '',
  reward_value: 0,
  partner_id: '',
};

export const rewardsConditionsDefaultValues = {
  start_at: null,
  end_at: formatISO(subDays(startOfDay(new Date()), 1)),
  target_countries: [],
  target_variant: '',
  daily_limit: '',
};

export const languages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'es', label: 'Spanish' },
  { value: 'it', label: 'Italian' },
  { value: 'zh', label: 'Simplified Chinese' },
  { value: 'ru', label: 'Russian' },
  { value: 'ar', label: 'Arabic' },
];

export const defaultLanguageData = {
  reward_link: '',
  reward_title: '',
  reward_message: '',
  reward_image_url: '',
  reward_description: '',
};

export const metricsFiltersDefaultValues = {
  from: format(startOfDay(new Date()), DATE_FORMAT),
  to: null,
};
